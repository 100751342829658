import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import api from '../../../api/api';
import { useTelegram } from '../../../components/useTelegram';
import cartImg from '../add-2-cart.svg';
import CartModal from './CartModal';
import styles from './ProductsComponent.module.css';
import cartAddedImg from '../../cart_icon_done.svg';
import ProductLabelComponent from '../../ProductLabelComponent';


const ProductsComponent = ({ products, storeID, scrollDistance, scrollPosition, setCartCount, cartData, setCartData }) => {

    const nav = useNavigate();
    const { user } = useTelegram();
    const [showModal, setShowModal] = useState(false);
    const [productCombosVarNames, setProductCombosVarNames] = useState({});
    const [currentProduct, setCurrentProduct] = useState({});
    const [selectedOptions, setSelectedOptions] = useState('');

    const [isAdded, setIsAdded] = useState(false);

    const closeModal = () => {
        setShowModal(!showModal);
        setProductCombosVarNames({});
        setIsAdded(false);
    };

    const openCartModal = (event, product) => {
        event.stopPropagation();

        setCurrentProduct(product);

        var defaultCombo;

        if (product.items.length > 1) {

            defaultCombo = product.items[0];

            const optionsInfo = {
                var1: {
                    name: product.variants.var1.name,
                    options: product.variants.var1.options,
                },
                var2: {
                    name: product.variants.var2.name,
                    options: product.variants.var2.options,
                }
            };

            setSelectedOptions(prev => ({
                ...prev,
                var1: defaultCombo.var1_value,
                var2: defaultCombo.var2_value
            }))

            setProductCombosVarNames(optionsInfo);

            setShowModal(true);
        } else {
            const cartItem = {
                user_id: user.id.toString(),
                store_id: storeID,
                var1_name: product.var1,
                var1_value: product?.var1_value,
                var2_name: product.var2,
                var2_value: product?.var2_value,
                quantity: 1,
                combo_id: product.id,
                product_id: product.product,
                name: product.name,
                price: product.price,
                total_price: product.total_price,
                photo: product?.photos[0]?.url,
                weight: product.weight,
                depth: product.depth,
                height: product.height,
                width: product.width,
                item_id: product.vendor_code,
                tax: product.tax,
            };

            api.addCartItem(cartItem).then((r) => {
                if (r.status !== 204) {
                    setIsAdded(true);
                    setCartCount(prev => prev + 1);
                    setCartData(prevData => [...prevData, cartItem.combo_id]);
                }
            });
        }
    };

    const handleAddToCart = () => {
        const filteredCombos = currentProduct.items.filter(item => { return item.var1_value === selectedOptions.var1 & item.var2_value === selectedOptions.var2 });

        if (filteredCombos.length > 0) {
            const cartItem = {
                user_id: user.id.toString(),
                store_id: storeID,
                quantity: 1,
                combo_id: filteredCombos[0]?.id,
                product_id: filteredCombos[0]?.product,
                name: filteredCombos[0]?.name,
                var1_name: productCombosVarNames.var1.name,
                var1_value: filteredCombos[0]?.var1_value,
                var2_name: productCombosVarNames.var2.name,
                var2_value: filteredCombos[0]?.var2_value,
                price: filteredCombos[0]?.price,
                total_price: filteredCombos[0]?.total_price,
                photo: filteredCombos[0]?.photos[0]?.url,
                weight: filteredCombos[0]?.weight,
                depth: filteredCombos[0]?.depth,
                height: filteredCombos[0]?.height,
                width: filteredCombos[0]?.width,
                item_id: filteredCombos[0]?.vendor_code,
                tax: filteredCombos[0]?.tax,
            }

            api.addCartItem(cartItem).then((r) => {
                if (r.status !== 204) {
                    setIsAdded(true);
                    setCartCount(prev => prev + 1);
                }
            });
        }
    };

    const goToCart = () => {
        nav(`/store/${storeID}/cart`)
    };

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={`${styles.products} ${scrollPosition >= scrollDistance ? styles.offset : ''}`}>
            {/* {memoizedProductCards} */}

            {products.map(product => (
                <div className={styles.productCard} key={uuid()} onClick={() => { nav(`/store/${storeID}/product/${product.product}`); }}>

                    <div className={styles.firstLine}>
                        <img src={product.photos[0]?.url} alt='Нет фото' className={styles.previewImg} />
                        <p className={styles.description}>{`${product.name} — ${product.description}`.substring(0, 66)}...</p>
                    </div>

                    <div className={styles.price}>
                        {product.price !== product.total_price ? (
                            <div className={styles.priceLeft}>
                                <span className={styles.discountPrice}>{formatPriceWithThousandSeparator(product.price)}</span>
                                <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                            </div>
                        ) : (
                            <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)}</span>
                        )}
                        {cartData.includes(product.id) ? <img src={cartAddedImg} alt="В корзине" /> : <img src={cartImg} alt="Добавить в корзину" onClick={(event) => {
                            event.stopPropagation();
                            openCartModal(event, product);
                        }} />}
                    </div>
                    {product.labels.length > 0 && <div className={styles.labels}>{product.labels.map(label => (<ProductLabelComponent labelStyle={label} />))}</div>}
                </div>
            ))}
            {products.length === 0 && <div className={styles.emptyCatalog}><span>В каталоге пусто</span></div>}
            <CartModal showModal={showModal} onClose={closeModal} productCombosVarNames={productCombosVarNames} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} handleAddToCart={handleAddToCart} isAdded={isAdded} setIsAdded={setIsAdded} goToCart={goToCart} />
        </div>
    );
};

export default ProductsComponent;
