import styles from "./Pagination.module.css";

const PaginationV2 = ({ totalPages, currentPage, onPageChange }) => {
    if (totalPages < 2) {
        return <></>;
    }

    return (
        <div className={styles.pagination}>
            {
                currentPage + 1 <= totalPages &&
                <span className={styles.nextButton} onClick={() => onPageChange(currentPage + 1)}>
                    Загрузить ещё
                </span>
            }
        </div >
    );
};

export default PaginationV2;
