import React, { useEffect, useState } from 'react';
import api from '../api/api';

function Poll({ userID, storeID }) {
    const [showThankYou, setShowThankYou] = useState(false);
    const [showPoll, setShowPoll] = useState(false);
    const [questionText, setQuestionText] = useState("Вам нравится магазин?");

    const handleAnswer = (answer) => {
        api.answerPoll(storeID, userID, questionText, answer).then(() => {
            setShowThankYou(true);
            setShowPoll(false);
        }).catch(e => {
            setShowPoll(false);
        });
    };

    const handleClose = () => {
        setShowPoll(false);
        api.answerPoll(storeID, userID, questionText, null);
    };

    useEffect(() => {
        api.getPoll(userID).then(r => {
            if (r?.data) {
                setQuestionText(r.data?.question_text);
                setShowPoll(true);
            }
        });
    }, []);

    useEffect(() => {
        if (showThankYou) {
            const timer = setTimeout(() => {
                setShowThankYou(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showThankYou]);

    return (
        <>
            {showPoll && (
                <div style={styles.overlay}>
                    <div style={styles.pollContainer}>
                        <div style={styles.questionContainer}>
                            <span style={styles.closeIcon} onClick={handleClose}>&times;</span>
                            <p style={styles.questionText}>{questionText}</p>
                            <p style={styles.noteText}>Анонимный опрос</p>
                            <div style={styles.buttonContainer}>
                                <button style={styles.button} onClick={() => handleAnswer(true)}>
                                    Да
                                </button>
                                <button style={styles.button} onClick={() => handleAnswer(false)}>
                                    Нет
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showThankYou && (
                <div style={styles.thankYouContainer}>
                    <p>Спасибо за ответ!</p>
                </div>
            )}
        </>
    );
}

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    pollContainer: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '24px',
        width: '300px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        textAlign: 'center',
    },
    questionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    questionText: {
        fontSize: '18px',
        marginBottom: '8px',
    },
    noteText: {
        fontSize: '12px',
        color: '#888',
        marginBottom: '16px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '12px',
    },
    button: {
        padding: '8px 16px',
        fontSize: '14px',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 'none',
        backgroundColor: '#419fd9',
        color: '#fff',
    },
    closeIcon: {
        position: 'absolute',
        top: '8px',
        right: '8px',
        fontSize: '18px',
        cursor: 'pointer',
    },
    thankYouContainer: {
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#419fd9',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1001,
    },
};

export default Poll;
