import React, { useEffect, useState } from 'react';
import styles from './ImageSlider.module.css';

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState(null);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        if (startX !== null) {
            const deltaX = e.touches[0].clientX - startX;

            // Свайп влево
            if (deltaX > 50 && currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
                setStartX(null);
            }
            // Свайп вправо
            else if (deltaX < -50 && currentIndex < images.length - 1) {
                setCurrentIndex(currentIndex + 1);
                setStartX(null);
            }
        }
    };

    useEffect(() => { setCurrentIndex(0); setStartX(null); }, [images])

    return (
        <div
            className={styles.slider}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            <div className={styles.slidesContainer} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {images.map((image, index) => (
                    <div key={index} className={styles.slide} >
                        <div><img src={image.url} alt={`Slide ${index + 1}`} /></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;
