import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import ProductLabelComponent from '../../ProductLabelComponent';
import styles from './ProductsComponent.module.css';


const ProductsComponent = ({ products, storeID, scrollDistance, scrollPosition }) => {

    const nav = useNavigate();

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        // <div className={`${styles.products} ${scrollPosition >= scrollDistance ? styles.offset : ''}`}>
        <div className={`${styles.products}`}>
            {products.map(product => (
                <div className={styles.productCard} key={uuid()} onClick={() => { nav(`/store/${storeID}/product/${product.product}`); }}>

                    <div className={styles.firstLine}>
                        {product.photos.length > 0 ? <img src={product.photos[0]?.url} alt='Нет фото' className={styles.previewImg} /> : <div className={styles.previewImg}><span>Нет фото</span></div>}
                        <p className={styles.description}>{`${product.name}`}</p>
                    </div>

                    <div className={styles.price}>
                        {product.total_price === 1 ? (
                            <div className={styles.priceLeft}>
                                <span className={styles.originalPrice}>Бесплатно</span>
                            </div>
                        ) : product.price !== product.total_price ? (
                            <div className={styles.priceLeft}>
                                <span className={styles.discountPrice}>{formatPriceWithThousandSeparator(product.price)} ₽</span>
                                <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)} ₽</span>
                            </div>
                        ) : (
                            <span className={styles.originalPrice}>{formatPriceWithThousandSeparator(product.total_price)} ₽</span>
                        )}
                    </div>
                    {product.labels.length > 0 && <div className={styles.labels}>{product.labels.map(label => (<ProductLabelComponent labelStyle={label} />))}</div>}
                </div>
            ))}
            {products.length === 0 && <div className={styles.emptyCatalog}><span>В каталоге пусто</span></div>}
        </div>
    );
};

export default ProductsComponent;
