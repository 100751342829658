import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../api/api';
import CartViewBox from '../../components/Boxberry/CartView/CartView';
import CartViewList from '../../components/List/CartView/CartView';
import CartViewWhite from '../../components/White/CartView/CartView';
import CartViewBlue from '../../components/Blue/CartView/CartView';
import CartViewAstroknight from '../../components/Astroknight/CartView/CartView';
import { useTelegram } from '../../components/useTelegram';

function CartPage() {
    const { user } = useTelegram();
    const nav = useNavigate();
    const [cartItems, setCartItems] = useState([]);
    const [template, setTemplate] = useState("0");
    const { storeID } = useParams();

    const [recommendedProduct, setRecommendedProduct] = useState(null);

    const tg = window.Telegram.WebApp;

    const setMainButtonOnClick = (callback) => {
        tg.MainButton.onClick(callback);
    };

    useEffect(() => {
        const template_code = localStorage.getItem('template_code');

        if (template !== undefined) {
            setTemplate(template_code);
        }

        var btn_color = '#000000';
        var txt_color = '#ffffff';

        if (template_code === "1") {
            btn_color = '#ed1651';
        }

        tg.BackButton.onClick(() => nav(`/store/${storeID}`));
        tg.BackButton.show();

        api.getCart(user.id.toString(), storeID).then((r) => {
            if (r.status === 200) {

                var cartItems = r.data?.items;
                cartItems = cartItems.map(el => {
                    const stockObj = r.data?.stock.filter(item => { return item.id === el.id })[0];
                    if (stockObj) {
                        return { ...el, stock: stockObj.stock };
                    } else {
                        return el;
                    }
                });

                setCartItems(cartItems);

                if (cartItems.length > 0) {
                    setMainButtonOnClick(() => nav(`/store/${storeID}/order`));
                    tg.MainButton.setParams({ text: 'Подтвердить заказ', color: btn_color, text_color: txt_color, is_active: true, is_visible: true });
                } else {
                    var btn_color = '#000000';
                    var txt_color = '#ffffff';

                    if (template === "1") {
                        btn_color = '#ed1651';
                    }
                    tg.MainButton.setParams({ text: 'В корзине пусто', color: btn_color, text_color: txt_color, is_active: false, is_visible: true });
                }

                if (template_code === "7" || template_code === "3") {
                    tg.MainButton.hide();
                }
            }
        });

        api.recommendProduct(user.id.toString(), storeID).then(r => setRecommendedProduct(r.data));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeID]);


    const removeFromCart = (cartItemID) => {
        api.removeCartItem(cartItemID).then((r) => {
            const updatedCart = cartItems.filter(item => item.id !== cartItemID);
            setCartItems(updatedCart);
        })
    };

    const incrementQuantity = (cartItemID) => {
        const currentCount = cartItems.filter(item => item.id === cartItemID);
        if (currentCount[0].quantity < currentCount[0].stock) {
            api.increaseCartItemQuantity(cartItemID).then((r) => {
                if (r.status === 200) {
                    setCartItems(prev => {
                        const updatedCart = prev.map(item => {
                            if (item.id === cartItemID) {
                                return { ...item, quantity: Math.min(currentCount[0].stock, item.quantity + item.add_to_cart_step) };
                            }
                            return item;
                        });
                        return updatedCart;
                    });
                }
            });
        }
    };

    const decrementQuantity = (cartItemID) => {
        const updatedCart = cartItems.map(item => {
            if (item.id === cartItemID && item.quantity > item.min_order_value) {
                return { ...item, quantity: Math.max(item.min_order_value, item.quantity - item.add_to_cart_step) };
            }
            return item;
        });
        setCartItems(prev => (updatedCart));
        api.decreaseCartItemQuantity(cartItemID);
    };

    const hideRecommendation = () => {
        const product = recommendedProduct;

        const step = Number.isInteger(parseInt(product.add_to_cart_step, 10))
            ? parseInt(product.add_to_cart_step, 10)
            : 1;
        const quantity = Number.isInteger(parseInt(product.min_order_value, 10))
            ? parseInt(product.min_order_value, 10)
            : 1;
        const cartItem = {
            user_id: user.id.toString(),
            store_id: storeID,
            quantity: quantity,
            add_to_cart_step: step,
            min_order_value: quantity,
            stock: product.stock,
            combo_id: product.id,
            product_id: product.product,
            name: product.name,
            var1_name: product.var1_name,
            var1_value: product.var1_value,
            var2_name: product.var2_name,
            var2_value: product.var2_value,
            price: product.price,
            total_price: product.total_price,
            photo: product.photos[0]?.url,
            weight: product.weight,
            depth: product.depth,
            height: product.height,
            width: product.width,
            item_id: product.vendor_code,
            tax: product.tax,
        }

        api.addCartItem(cartItem).then((r) => {
            if (r.status !== 204) {
                cartItem.id = r.data.id;
                cartItem.in_stock = true;
                setCartItems(prevCartItems => [...prevCartItems, cartItem]);
            }
        });

        setRecommendedProduct(null);
    };

    return (
        <>
            {
                template === "0" ? (
                    <CartViewWhite data={cartItems} storeID={storeID} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} removeFromCart={removeFromCart} recommendedProduct={recommendedProduct} hideRecommendation={hideRecommendation} />
                ) : template === "1" ? (
                    <CartViewBox data={cartItems} storeID={storeID} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} removeFromCart={removeFromCart} recommendedProduct={recommendedProduct} hideRecommendation={hideRecommendation} />
                ) : template === "2" ? (
                    <CartViewList data={cartItems} storeID={storeID} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} removeFromCart={removeFromCart} recommendedProduct={recommendedProduct} hideRecommendation={hideRecommendation} />
                ) : template === "3" ? (
                    <CartViewBlue data={cartItems} storeID={storeID} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} removeFromCart={removeFromCart} recommendedProduct={recommendedProduct} hideRecommendation={hideRecommendation} />
                ) : template === "99" ? (
                    <CartViewAstroknight data={cartItems} storeID={storeID} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} removeFromCart={removeFromCart} recommendedProduct={recommendedProduct} hideRecommendation={hideRecommendation} />
                ) : (
                    <CartViewWhite data={cartItems} storeID={storeID} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} removeFromCart={removeFromCart} recommendedProduct={recommendedProduct} hideRecommendation={hideRecommendation} />
                )}

        </>
    );
}

export default CartPage;
