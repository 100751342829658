import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../../../Constants.css";
import RecommendationCard from '../../RecommendationCard';
import removeButton from '../remove_cross.svg';
import styles from './CartView.module.css';

const CartItem = ({ item, incrementQuantity, decrementQuantity, removeFromCart }) => {

    const handleDelete = () => {
        removeFromCart(item.id);
    };

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <>
            <div className={`${styles.card}`} >
                <div id={item.id ? `card_${item.id}` : ''} className={styles.content}>
                    <div className={styles.upperRow}>
                        <img src={item.photo} alt={item.name} className={styles.itemPhoto} />
                        <p className={styles.itemName}>{item.name}{item.var1_value === "" ? '' : `, ${item.var1_value}`}{item.var2_value === "" ? '' : `, ${item.var2_value}`}</p>
                        <img className={styles.remove} src={removeButton} alt="remove" onClick={handleDelete} />
                    </div>

                    <div className={styles.lowerRow}>
                        <div className={styles.priceManagment}>
                            <div className={styles.quantityArea}>
                                <div className={styles.quantityControl}>
                                    <span className={styles.decreaseBtn} onClick={() => decrementQuantity(item.id)}>-</span>
                                    <span className={styles.quantity}>{item.quantity}</span>
                                    <span className={styles.increaseBtn} onClick={() => incrementQuantity(item.id)}>+</span>
                                </div>

                                {item?.stock && <div className={styles.avaibleStock}>
                                    {item.stock < 10000 && <span>Доступно: {item.stock}</span>}
                                </div>}
                            </div>

                            <div>
                                <div className={styles.pricesContainer}>
                                    <span className={styles.price}>{formatPriceWithThousandSeparator(item.total_price)} ₽</span>
                                    {item.price && item.price > item.total_price ? (
                                        <span className={styles.oldPrice}>{formatPriceWithThousandSeparator(item.price)} ₽</span>
                                    ) : null}
                                </div>
                                <span className={styles.finalPrice}>{formatPriceWithThousandSeparator(item.total_price * item.quantity)} ₽</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

const CartView = ({ data, storeID, incrementQuantity, decrementQuantity, removeFromCart, recommendedProduct, hideRecommendation }) => {

    const calculateTotalPrice = () => {
        const price = data.reduce((total, item) => total + (item.total_price * item.quantity), 0).toFixed();
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.menu}>
                    <div className={styles.navBtn}>
                    </div>
                    <h2>Корзина</h2>
                </div>

                <div className={styles.totalPrice}>
                    <span>Итого:</span>
                    <span>{calculateTotalPrice()} &#8381;</span>
                </div>
            </div>
            <div className={styles.scrollLine} />
            {data.length === 0 ? (
                <div className={styles.emptyCart}>
                    <h1>Сложите в корзину нужные товары</h1>
                    <p>А чтобы их найти, загляните в каталог</p>
                    <button onClick={() => { navigate(`/store/${storeID}`) }}>Обратно в каталог</button>
                </div>
            ) : (
                <>
                    <div id={'card_container'} className={styles.cartItems}>
                        {data.filter(product => product.in_stock).map((item, idx) => (
                            <CartItem key={idx} item={item} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} removeFromCart={removeFromCart} />
                        ))}
                        {recommendedProduct && <RecommendationCard product={recommendedProduct} storeID={storeID} hideRecommendation={hideRecommendation} />}
                        {data.filter(product => !product.in_stock).length > 0 && (<div className={styles.outOfStockTitle}><span>Нет в наличии:</span></div>)}
                        {data.filter(product => !product.in_stock).map((item, idx) => (
                            <CartItem key={idx} item={item} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} removeFromCart={removeFromCart} />
                        ))}
                    </div>
                    <div className={styles.orderSummary}>
                        <button className={styles.orderBtn} onClick={() => { navigate(`/store/${storeID}/order`) }}>Подтвердить заказ</button>
                    </div>
                </>
            )}
        </div>
    );
};

export default CartView;

