import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import { Link, useNavigate } from 'react-router-dom';
import "../../../Constants.css";
import InfoModal from '../../InfoModal';
import pickupIcon from '../../pickup_icon.svg';
import PointSelectionMap from '../../PointSelectionMap';
import removeButton from '../remove_cross.svg';
import styles from './CheckoutView.module.css';

const CheckoutView = ({ storeID, orderFields, handleSubmit, name, setName, phone, setPhone, email, setEmail, inn, setInn, comment, setComment, deliveryType, selectedOption, deliveryAddress, selectedPoint, setSelectedPoint, setSelectedOption, shippingPrice, setShippingPrice, handleSelectPoint, address, setAddress, cartItems, showModal, isMapOpen, setIsMapOpen, showTitle, showText, closeInfoModal, isDeliveryFree, isPaymentConnected, paymentMessage, selectedDate, setSelectedDate }) => {

    const navigate = useNavigate();

    const tg = window.Telegram.WebApp;

    const stopActionEnter = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
        }
    };

    const handleAddressChange = (field, value) => {
        setAddress({
            ...address,
            [field]: value,
        });
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setShippingPrice(0);
        setSelectedPoint(null);
    };

    const handlePhoneNumberChange = (event) => {
        const inputPhoneNumber = event.target.value.replace(/\D/g, '');
        setPhone(inputPhoneNumber);
    };

    const calculateTotalPrice = () => {
        return cartItems.reduce((total, item) => total + (item.total_price * item.quantity), 0);
    };

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    function isINN(num) {
        return !isNaN(num);
    };

    const [showInnField, setShowInnField] = useState(false);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.scrollLine} />
            </div>

            <form onSubmit={handleSubmit}>
                <div className={styles.formBaseSection}>
                    <h2>Оформление заказа</h2>
                    {orderFields.name && <input
                        type="text"
                        id="name"
                        onKeyDown={stopActionEnter}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder='Ваше имя'
                        required
                    />}
                    {true && <InputMask
                        type="tel"
                        id="phone"
                        onKeyDown={stopActionEnter}
                        value={phone}
                        mask="+7 (999) 999-99-99"
                        placeholder="Ваш номер телефона"
                        onChange={(e) => handlePhoneNumberChange(e)}
                        required
                    />}
                    {orderFields.email && <input
                        type="email"
                        id="email"
                        onKeyDown={stopActionEnter}
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        placeholder='Ваш e-mail'
                        required
                    />}
                    {orderFields.inn && (
                        <>
                            <div className={styles.checkboxContainer}>
                                <input
                                    type="checkbox"
                                    id="showInnField"
                                    checked={showInnField}
                                    onChange={() => setShowInnField(!showInnField)}
                                    className={styles.checkboxInput}
                                />
                                <label htmlFor="showInnField" className={styles.checkboxLabel}>
                                    Оформить заказ как юридическое лицо
                                </label>
                            </div>

                            {showInnField && (
                                <div>
                                    <input
                                        type="text"
                                        id="inn"
                                        onKeyDown={stopActionEnter}
                                        value={inn}
                                        onChange={(e) => {
                                            if (isINN(e.target.value)) setInn(e.target.value);
                                        }}
                                        placeholder="ИНН вашей организации"
                                        required
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {orderFields.custom_date && <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={orderFields.custom_date_title}
                        id="date"
                        minDate={new Date()}
                        required
                    />}
                    {orderFields.comment && <input
                        type="text"
                        id="comment"
                        onKeyDown={stopActionEnter}
                        value={comment}
                        onChange={(e) => { if (e.target.value.length < 128) setComment(e.target.value) }}
                        placeholder='Комментарий'
                    />}
                </div>

                <div className={styles.formDeliverySection}>
                    <h2>Информация о доставке</h2>
                    {deliveryType.length > 1 & deliveryType.length < 4 ? (
                        <div className={styles.optionsChoice}>
                            {deliveryType === 'pickup' | deliveryType.includes('1') ? (
                                <button type='button' onClick={() => handleOptionClick('pickup')} className={selectedOption === 'pickup' ? styles.selected : ''}>Самовывоз</button>
                            ) : null}

                            {deliveryType.includes('2') ? (
                                <button type='button' onClick={() => handleOptionClick('boxberry')} className={selectedOption === 'boxberry' ? styles.selected : ''}>Доставка Boxberry</button>
                            ) : null}

                            {deliveryType === 'delivery' | deliveryType.includes('3') ? (
                                <button type='button' onClick={() => handleOptionClick('seller')} className={selectedOption === 'seller' ? styles.selected : ''}>Доставка продавца</button>
                            ) : null}

                            {deliveryType.includes('4') ? (
                                <button type='button' onClick={() => handleOptionClick('online')} className={selectedOption === 'online' ? styles.selected : ''}>online</button>
                            ) : null}
                        </div>
                    ) : null}
                </div>

                <div className={styles.formAdressSection}>
                    {selectedOption === 'online' && <div className={styles.pickupInfo}><p>Доставка цифровых товаров осуществляется продавцом дистанционным способом после подтверждения покупки. После завершения заказа продавец свяжется с вами и согласует способ доступа к приобретенному контенту. Пожалуйста, убедитесь, что указали правильный адрес электронной почты при оформлении заказа. Если у вас возникли вопросы или проблемы с доставкой, не стесняйтесь связаться с нашей службой поддержки.</p></div>}

                    {selectedOption === 'pickup' && <div className={styles.pickupInfo}><img src={pickupIcon} alt="pickup" /><p>{deliveryAddress}</p></div>}

                    {selectedOption === 'boxberry' && (
                        <div className={styles.boxberrySection}>
                            {selectedPoint && (
                                <div className={styles.pointInfo}>
                                    <strong>Пункт выдачи Boxberry:</strong>
                                    <br />
                                    <span>{selectedPoint?.Address}</span>
                                </div>
                            )}
                            <div className={styles.mapController}>
                                <button type='button' onClick={() => setIsMapOpen(true)} className={styles.mapOpen}>
                                    {selectedPoint ? 'Изменить выбор' : 'Выбрать пункт выдачи'}
                                </button>
                            </div>

                            {isMapOpen && (
                                <div className={`${styles.mapModal} ${isMapOpen ? styles.visible : ''}`}>
                                    <div className={styles.mapContainer}>
                                        <PointSelectionMap onSelect={handleSelectPoint} />
                                        <button className={styles.closeButton} onClick={() => setIsMapOpen(false)}>
                                            <img src={removeButton} alt="" />
                                        </button>
                                        <p className={styles.bottomLabel}>© OpenStreetMap</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {selectedOption === 'seller' && (
                        <div className={styles.addressForm}>
                            <div className={styles.addressRow}>
                                <div className={styles.addressField}>
                                    <label>Город:</label>
                                    <input
                                        type="text"
                                        placeholder="Ваш город"
                                        value={address.city}
                                        onChange={(e) => handleAddressChange('city', e.target.value)}
                                    />
                                </div>

                                <div className={styles.addressField}>
                                    <label>Улица:</label>
                                    <input
                                        type="text"
                                        placeholder="Ваша улица"
                                        value={address.street}
                                        onChange={(e) => handleAddressChange('street', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={styles.addressRow}>
                                <div className={styles.addressField}>
                                    <label>Дом:</label>
                                    <input
                                        type="text"
                                        placeholder="Ваш дом"
                                        value={address.house}
                                        onChange={(e) => handleAddressChange('house', e.target.value)}
                                    />
                                </div>

                                <div className={styles.addressField}>
                                    <label>Квартира:</label>
                                    <input
                                        type="text"
                                        placeholder="Ваша квартира"
                                        value={address.apartment}
                                        onChange={(e) => handleAddressChange('apartment', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.paymentSection}>
                    <h2>Оплата</h2>
                    {isPaymentConnected ? <span>{paymentMessage} Безопасность транзакций гарантирована использованием технологии 3D-Secure.</span> : <span>{paymentMessage}</span>}

                    <div className={styles.finalPrice}>
                        {shippingPrice === 0 | isDeliveryFree ? null : <p><strong> Стоимость доставки: {`${formatPriceWithThousandSeparator(shippingPrice)} Руб`}</strong></p>}
                        <p><strong> Стоимость товаров: {formatPriceWithThousandSeparator(calculateTotalPrice())} Руб</strong></p>
                    </div>

                    <div className={styles.finalPrice}>
                        {isDeliveryFree ? <h2>Итого: <strong>{formatPriceWithThousandSeparator(calculateTotalPrice())} Руб</strong></h2> : <h2>Итого: <strong>{formatPriceWithThousandSeparator(calculateTotalPrice() + shippingPrice)} Руб</strong></h2>}
                    </div>

                    <div className={styles.agreeText}>
                        <span>Оформляя заказ, Вы даете согласие на <Link to={'https://docs.thebotique.ru/privacy-policy-agreement'}>обработку персональных данных</Link> и подтверждаете, что ознакомлены с <Link to={'https://docs.thebotique.ru/return-policy'}>правилами возврата</Link></span>
                    </div>
                </div>

                {showModal ? null : (
                    <button type="submit" className={styles.orderBtn}>
                        Оформить заказ
                    </button>
                )}
            </form>
            <InfoModal showModal={showModal} onClose={() => { closeInfoModal ? tg.close() : navigate(`/store/${storeID}`) }} title={showTitle} text={showText} />
        </div>
    );
};

export default CheckoutView;
