import React, { useState } from 'react';
import styles from './ProductView.module.css';

function Description({ description, name }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 128;

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded); // Переключение состояния
    };

    const formattedDescription = description.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            {index !== description.split('\n').length - 1 && <br />}
        </React.Fragment>
    ));

    return (
        <div>
            <p className={styles.description}>
                <span>{name} — </span>
                {isExpanded
                    ? formattedDescription
                    : `${description.substring(0, maxLength)}...`}
                <button onClick={toggleExpanded} className={styles.toggleBtn}>
                    {isExpanded ? 'Скрыть' : 'Читать дальше'}
                </button>
            </p>
        </div>
    );
}

export default Description;
